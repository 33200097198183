import Vue from 'vue'
import Router from 'vue-router'
import firebase from 'firebase';

const DefaultContainer = () => import('@/containers/DefaultContainer');

const Dashboard = () => import('@/views/sections/dashboard/Dashboard');
const Establishments = () => import('@/views/sections/membership/Establishments');
const Benefits = () => import('@/views/sections/membership/Benefits');
const Details = () => import('@/views/sections/membership/Details');

const Page404 = () => import('@/views/pages/Page404');
const Page500 = () => import('@/views/pages/Page500');
const Login = () => import('@/views/pages/Login');
const Register = () => import('@/views/pages/Register');

import {bus} from "../main";

Vue.use(Router);

const router = new Router({
    mode: 'hash', // https://router.vuejs.org/api/#mode
    linkActiveClass: 'open active',
    scrollBehavior: () => ({y: 0}),
    routes: [
        {
            path: '/',
            redirect: '/sections/dashboard',
            name: 'Home',
            component: DefaultContainer,
            children: [
                {
                    path: 'sections',
                    redirect: '/sections/dashboard',
                    name: 'Dashboard',
                    component: {
                        render(c) {
                            return c('router-view')
                        }
                    },
                    children: [
                        {
                            path: 'dashboard',
                            name: 'Dashboard',
                            component: Dashboard,
                            meta: {
                                requiresAuth: true,
                            }
                        },
                        {
                            path: 'establishments',
                            name: 'Establishments',
                            component: Establishments,
                            meta: {
                                requiresAuth: true,
                            }
                        },
                        // {
                        //     path: 'benefits',
                        //     name: 'Benefits',
                        //     component: Benefits,
                        //     meta: {
                        //         requiresAuth: true,
                        //     }
                        // },
                        {
                            path: 'details',
                            name: 'Details',
                            component: Details,
                            meta: {
                                requiresAuth: true,
                            }
                        },
                    ],
                },
            ]
        },

        {
            path: '/pages',
            redirect: '/pages/login',
            name: 'Pages',
            component: {
                render(c) {
                    return c('router-view')
                }
            },
            children: [
                {
                    path: '404',
                    name: 'Page404',
                    component: Page404
                },
                {
                    path: '500',
                    name: 'Page500',
                    component: Page500
                },
                {
                    path: 'login',
                    name: 'Login',
                    component: Login,
                    meta: {
                        requiresAuth: false,
                    }
                },
                {
                    path: 'register',
                    name: 'Register',
                    component: Register,
                    meta: {
                        requiresAuth: false,
                    }
                }
            ]
        },
        {
            path: '*',
            redirect: '/pages/login'
        }
    ]
});

router.beforeEach((to, from, next) => {
    const currentUser = firebase.auth().currentUser;
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    let scopes = [];
    if(typeof to.meta.scopes != 'undefined')
        scopes = to.meta.scopes;

    console.log(to.name + ' scopes: ' + scopes);

    console.log('route ' + from.name + ' -> ' + to.name + ' requires auth: ' + requiresAuth);
    console.log('user authorized: ' + (currentUser != null));

    if ((requiresAuth && !currentUser)) {
        console.log('routing to login...');
        next('login');
        // } else if (!requiresAuth && currentUser) {
        //   console.log('routing to dashboard...');
        //   // next('dashboard');
        //   next();
    } else {
        next();
    }
});

export default router;
