function strings_config() {
  String.prototype.format = function () {
    var a = this;
    for (var k in arguments) {
      a = a.replace(new RegExp("\\{" + k + "\\}", 'g'), arguments[k]);
    }
    return a
  }
}

export {
  strings_config
};
