import Vue from 'vue'
import App from './App.vue'
import router from './router/index'

Vue.config.productionTip = false

import BootstrapVue from 'bootstrap-vue'

Vue.use(BootstrapVue);

import VueResouce from 'vue-resource'

Vue.use(VueResouce);

//  -------------------------------------
import Toasted from 'vue-toasted';

Vue.use(Toasted);
//  -------------------------------------

//  -------------------------------------
//  material
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'

Vue.use(VueMaterial);
//  -------------------------------------

export const bus = new Vue();

//  -------------------------------------
//  firebase
import {firestorePlugin} from 'vuefire'
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'

let app = '';

firebase.initializeApp({
    apiKey: "AIzaSyD2gRrcTmWkfrBvlvS5Ccl4EhJhzbGssoE",
    authDomain: "aussiety.firebaseapp.com",
    databaseURL: "https://aussiety.firebaseio.com",
    projectId: "aussiety",
    storageBucket: "aussiety.appspot.com",
    messagingSenderId: "1057682053953",
    appId: "1:1057682053953:web:6ce9c7e1b771a316a15fc0",
    measurementId: "G-C2HRHCWXBB"
});

export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage().ref();

Vue.use(firestorePlugin);
//  -------------------------------------


//  -------------------------------------
import VueSlideoutPanel from 'vue2-slideout-panel';
Vue.use(VueSlideoutPanel);
//  -------------------------------------


// ---------------
import GoogleMapsApiLoader from 'google-maps-api-loader';
import {API_KEY} from '@/constants/mapSettings';

async function getGoogle() {
    console.log('getGoogle()');

    let googleMapApi;
    await GoogleMapsApiLoader({
        // libraries: ['places'],
        apiKey: API_KEY
    }).then(function (_googleMapApi) {
        // console.log('GoogleMapsApiLoader()');
        // console.log(_googleMapApi);
        googleMapApi = _googleMapApi;
    }, function (err) {
        console.error(err);
    });
    let google = googleMapApi;

    console.log(google);
    return google;
}

export const googlePromise = getGoogle();
// ---------------




// ---------------
import VueQrcode from '@chenfengyuan/vue-qrcode';

Vue.component(VueQrcode.name, VueQrcode);
// ---------------


firebase.auth().onAuthStateChanged(() => {
    console.log('firebase initialized');
    if (!app) {
        console.log('main.js parsed');
        app = new Vue({
            el: '#app',
            router,
            template: '<App/>',
            components: {
                App
            }
        });
    }
});

import {strings_config} from './utils/string';

strings_config();

